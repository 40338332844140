li,
.dropdown-item,
a {
  list-style-type: none;
}

li,
.dropdown-item,
a {
  text-decoration: none !important;
  color: black !important;
}

.cursor {
  cursor: pointer;
}

.cl {
  color: #068fff;
  margin-right: 5px;
}

.gHNpyS {
  border: 1px solid #d8d9da !important;
}

* {
  margin: 0%;
  padding: 0%;
  box-sizing: border-box;
}

.sidenav {
  width: 250px;
  width: 230px;
  flex: 0 0 auto;
  transition: width 0.3s ease-in-out;
  height: 200vh;
  background-color: #f9f9f9;
  padding-top: 28px;
}


.sideitem {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  cursor: pointer;
  color: black;
  text-decoration: none;
}


.h_p:hover {
  background-color: green !important;
}

.sideitem:hover {
  background-color: #090a0be3;
  background-color: white;
  background-color: #ff006e;
  color: white !important;
}

.active {
  color: #068fff;
}

.active1:hover {
  background-color: rgb(181, 45, 45);
}

.menuBtn {
  align-self: center;
  align-self: flex-start;
  justify-self: flex-end;
  color: black;
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding-left: 20px;
}

body {
  margin: 0%;
  padding: 0%;
}

.App {
  display: flex;
}

main {
  width: 100%;
}


.img {
  display: flex;
  padding: 10px;
  flex-direction: column;
  justify-content: center;
  color: black;
  margin: auto;
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.sidebar {
  background-color: rgb(80, 78, 78);
  color: white;
  height: fit-content;
}

Link {
  list-style-type: none;
}

Link:hover {
  background-color: aliceblue;
  color: black;
}



.form-container {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;

  background-color: #f7f7f7;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Form input fields */
.form-input {
  margin-bottom: 20px;
}

.form-input label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  transition: border-color 0.3s ease;
}

.form-input input[type="text"]:focus,
.form-input input[type="text"]:focus,
.form-input input[type="number"]:focus,
.form-input input[type="url"]:focus {
  outline: none;
  border-color: #5a67d8;
}

/* Error message */
.error-message {
  color: #e53e3e;
  margin-top: 5px;
}

/* Submit button */
.submit-btn {
  display: block;
  width: 100%;
  padding: 10px;
  background-color: #5a67d8;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submit-btn:hover {
  background-color: #434190;
}


.message {
  width: 400px !important;
}



/* Container for the user information row */
.user-info-row {
  display: flex;
  align-items: center;
  padding: 16px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 16px;
}

/* Styling for the back button */
.user-info-row .back-button {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.user-info-row .back-button img {
  width: 20px;
  height: 20px;
}

.LandingPageComponent01ContentBtn {
  padding: 0px 5px;
  margin-top: 8px;
  padding: 3px;
  font-size: 1rem;
  font-weight: 500;
  color: white;
  background-color: #ff006e;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-right: 10px;
}

.LandingPageComponent01ContentBtn.active{
  background-color: #321aca;
}

.css-g9in59 {
  width: 60%;
}

.textbold {

  font-weight: 600;
  font-size: 20px;
}

.message1 {
  color: rgb(94, 94, 94);
  text-align: justify;
}

.bRGCiY {
  justify-content: center !important;
  padding-top: 10px !important;
}

.edit-data {
  position: absolute;
  background-color: rgba(255, 255, 255, 0.281);
  box-shadow: 7px 7px 7px white;
  left: 20%;
  width: 100px;
  z-index: 999 !important;
  padding: 5px;

}

.Nfhbm {
  overflow-x: scroll !important;
  overflow-y: scroll !important;
}
.dxEqmY{
  justify-content: center !important;
}